import { err, ok } from 'neverthrow';

import MessageQueue, { Message, MessageQueueBatchResult } from '../../utils/queue';
import {
  QueueSendConfigurationError,
  QueueSendHttpError,
  QueueSendNetworkError,
} from '../../utils/queue/QueueSendError';
import captureError from '../ErrorReporting/captureError';
import ObservabilityMetadata from '../ObservabilityMetadata';

import { SPLUNK_API_KEYS } from './config';
import { LogSeverity, SPLUNK_URL } from './constants';
import {
  getApiMapping,
  getDevicePlatform,
  getMetadata,
  isLoggingToSplunkEnabled,
  logLocal,
} from './internals';
import LoggingMessage from './LoggingMessage';
import { LoggingMetadata } from './types';
import { getApiEnvironmentName } from './utils';

export type LoggingMessageBatch = Array<Message<LoggingMessage>>;

export default class Logger {
  private static queue: MessageQueue<LoggingMessage> = new MessageQueue(Logger.sendBatch);

  static platform(): string {
    return getDevicePlatform();
  }

  static async host(): Promise<string | null> {
    return getApiMapping();
  }

  static async splunkIndex(): Promise<string | null> {
    const currentPlatform: string = Logger.platform().toLowerCase();
    const apiEnvironmentName = await getApiEnvironmentName(currentPlatform);

    return apiEnvironmentName ? `${currentPlatform}_${apiEnvironmentName}` : null;
  }

  static async splunkToken(): Promise<string | null> {
    const index = await Logger.splunkIndex();
    return index ? SPLUNK_API_KEYS[index] : null;
  }

  static async platformMetadata(): Promise<Partial<LoggingMetadata>> {
    return getMetadata();
  }

  static info(message: string, metadata: Record<string, string> = {}): void {
    Logger.log(message, metadata, LogSeverity.INFO);
  }

  static debug(message: string, metadata: Record<string, string> = {}): void {
    Logger.log(message, metadata, LogSeverity.DEBUG);
  }

  static warn(message: string, metadata: Record<string, string> = {}): void {
    Logger.log(message, metadata, LogSeverity.WARN);
  }

  static error(error: Error, metadata: Record<string, string> = {}): void {
    Logger.log(error.message, metadata, LogSeverity.ERROR, error);
    captureError(error);
  }

  private static async sendBatch(messageBatch: LoggingMessageBatch): MessageQueueBatchResult {
    const [host, splunkIndex, splunkToken] = await Promise.all([
      Logger.host(),
      Logger.splunkIndex(),
      Logger.splunkToken(),
    ]);
    const logPayload = Logger.makePayload(messageBatch, host, splunkIndex);

    try {
      const postResult = await fetch(SPLUNK_URL, {
        method: 'POST',
        headers: {
          Authorization: `Splunk ${splunkToken}`,
        },
        body: logPayload,
      });

      if (postResult.ok) {
        // return ok() with the message uuids so the queue marks them as done
        return ok(messageBatch.map((message) => message.id));
      }

      if (postResult.status === 403) {
        return err(
          new QueueSendConfigurationError( // this is NOT a retryable error
            'Auth error sending logs to Splunk, is the token being set correctly?'
          )
        );
      }

      // general http error handling, this is a retryable error
      return err(
        new QueueSendHttpError(
          `HTTP error sending logs to Splunk. Status: ${
            postResult.status
          } Message: ${await postResult.text()}`
        )
      );
    } catch (e) {
      // fetch throws for improper fetch() calls, aborted network requests, and network (not server) errors
      const message = e instanceof Error ? e.message : 'Unknown error';
      return err(
        new QueueSendNetworkError( // this is a retryable error
          `Network error sending logs to Splunk: ${message}`
        )
      );
    }
  }

  private static makePayload(
    messages: LoggingMessageBatch,
    host: string | null,
    index: string | null
  ): string {
    return messages
      .map((message) => {
        return JSON.stringify({
          event: {
            message: message.content.message,
            severity: message.content.severity,
            metadata: message.content.metadata,
            error: message.content.error,
          },
          host,
          index,
          source: Logger.platform(),
          time: Date.now().toString(),
        });
      })
      .join('\n');
  }

  private static async log(
    message: string,
    metadata: Record<string, string>,
    severity: LogSeverity,
    error?: Error
  ): Promise<void> {
    const observabilityMetadata = ObservabilityMetadata.data();
    const platformMetadata = await Logger.platformMetadata();

    const mergedMetadata = {
      ...metadata,
      ...observabilityMetadata,
      ...platformMetadata,
    };

    // log to the console in every build and deploy env,
    // no reason not to since all this info will be going over the network anyway
    logLocal(message, mergedMetadata, severity, error);

    // don't log to splunk in places it doesn't work
    if (!(await isLoggingToSplunkEnabled())) return;

    if (!this.queue.isStarted()) {
      this.queue.start();
    }

    const log = new LoggingMessage({
      message,
      severity,
      metadata: mergedMetadata,
      error,
    });
    this.queue.push(log);
  }
}
