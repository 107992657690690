export const SPLUNK_API_KEYS = {
  android_production: '0B0CA87D-E414-43F3-ABDA-1ED247451A91',
  android_debug: '06D3AF69-6CED-4337-8D49-662E4A748C6D',
  ios_production: 'BBD92DAD-9712-4378-B4F4-02390D3965CD',
  ios_debug: '9BEAD584-7F13-4EE4-8E10-94C5B4BC1FF8',
  web_client_production: '4d9b9c09-1409-41c2-b9b0-29f9de50cf2c',
  web_client_staging: '6fc00218-398d-482c-be9b-546b411890e1',
  ssr_server_production: 'TODO',
  ssr_server_staging: 'TODO',
};

// only affect client side, the SSR server always logs metadata
export const LOCAL_LOGS_INCLUDE_METADATA = false;
