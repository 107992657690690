import { setupI18n } from '@lingui/core';

import { DEFAULT_LANG } from '@rover/shared/js/constants/i18n/language';
import inject from '@rover/utilities/injector';

const language = inject('Rover.context.language', DEFAULT_LANG);
const appCatalog = inject('i18nCatalog', {
  languageData: {},
  messages: {},
});
const reactLibCatalog = inject('i18nCatalogReactLib', {
  languageData: {},
  messages: {},
});
const utilitiesCatalog = inject('i18nUtilitiesCatalog', {
  languageData: {},
  messages: {},
});
const sharedCatalog = inject('i18nSharedCatalog', {
  languageData: {},
  messages: {},
});
const i18nCatalogRSDK = inject('i18nCatalogRSDK', {
  languageData: {},
  messages: {},
});
const i18nCatalogKibble = inject('i18nCatalogKibble', {
  languageData: {},
  messages: {},
});
const i18nCatalogPages = inject('i18nCatalogPages', {
  languageData: {},
  messages: {},
});
const languageCatalog = {
  languageData: appCatalog.languageData,
  messages: {
    ...appCatalog.messages,
    ...reactLibCatalog.messages,
    ...utilitiesCatalog.messages,
    ...sharedCatalog.messages,
    ...i18nCatalogRSDK.messages,
    ...i18nCatalogKibble.messages,
    ...i18nCatalogPages.messages,
  },
};
const i18nConfig = {
  language,
  catalogs: {
    [language]: languageCatalog,
  },
};
const i18n = setupI18n(i18nConfig);
export { i18n as default, i18nConfig };
