/* eslint-disable rover/no-platform-specific-globals-or-imports */
import bowser from 'bowser';
import type { Request } from 'express';

import { MOBILE_EMBEDDED_HEADER } from '@rover/rsdk/src/modules/Network/constants';

import httpContext from './httpContext';

type UtilFunction = (userAgentString?: string | null | undefined) => boolean;
// this return type stinks but otherwise each function
// has to coerce its return type to a boolean rather than
// just doing the normal JS thing. UtilFunction ends
// up being the exposed one anyway so we still get a
// (relatively) clean public API anyway.
type BowserFunction = (arg0: string | null | undefined) => (boolean | '') | null | undefined;
export const MOBILE_EMBEDDED_COOKIE = 'mobile-embedded';
export const EMBEDDED_WEB_CLASS_NAME = 'mobile-embedded';
export const BOT_USER_AGENTS = [
  // Taken from https://github.com/selwin/python-user-agents/blob/master/user_agents/devices.json
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Mozilla/5.0 (iPhone; U; CPU iPhone OS) (compatible; Googlebot-Mobile/2.1; http://www.google.com/bot.html)',
  'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'DoCoMo/2.0 N905i(c100;TB;W24H16) (compatible; Googlebot-Mobile/2.1; +http://www.google.com/bot.html)',
];
export const isWindowNavigatorUserAgentDefined = (): boolean => {
  if (typeof window === 'undefined') return false;
  const global = window;
  return (
    global &&
    global.navigator &&
    typeof global.navigator.userAgent === 'string' &&
    global.navigator.userAgent.length > 0
  );
};
export const getUserAgentFromExpress = (): string | null | undefined => {
  if (process.env.JS_ENV_SERVER) {
    const req = httpContext.getReq();
    return req?.get('User-Agent') || null;
  }

  return isWindowNavigatorUserAgentDefined() ? window.navigator.userAgent : null;
};

// Takes in a BowserFunction and makes it safe to call with or without a string arg or the window present
export const safeWindowFunc =
  (bowserFuncToWrap: BowserFunction): UtilFunction =>
  (userAgentString: string | null | undefined): boolean => {
    if (userAgentString) {
      return !!bowserFuncToWrap(userAgentString);
    }

    if (isWindowNavigatorUserAgentDefined()) {
      return !!bowserFuncToWrap(window.navigator.userAgent);
    }

    return !!bowserFuncToWrap(null);
  };

// Googlebot utils
export const isGooglebot: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && userAgentString.includes('Googlebot')
);
export const isMobileGooglebot: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && userAgentString.includes('Googlebot') && userAgentString.includes('Mobile')
);
export const isDesktopGooglebot: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && userAgentString.includes('Googlebot') && !userAgentString.includes('Mobile')
);

// browser metadata functions
export type UserAgentMetadata = {
  browserName?: string;
  browserVersion?: string;
  osName?: string;
  osVersion?: string;
};

export function getUserAgentMetadata(): UserAgentMetadata {
  if (isWindowNavigatorUserAgentDefined()) {
    const result = bowser.parse(window.navigator.userAgent);
    return {
      browserName: result.browser.name,
      browserVersion: result.browser.version,
      osName: result.os.name,
      osVersion: result.os.versionName,
    };
  }
  return {};
}

// bowser-based functions
export const isAndroid: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getOSName() === 'Android'
);
export const isWindowsPhone: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getOSName() === 'Windows Phone'
);
export const isKindle: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getPlatform().vendor === 'Amazon'
);
export const isiOS: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getOSName() === 'iOS'
);
export const isiOS9: UtilFunction = safeWindowFunc((userAgentString: string | null | undefined) => {
  if (!userAgentString) {
    return false;
  }

  const parser = bowser.getParser(userAgentString);
  return (
    parser.getOSName() === 'iOS' &&
    typeof parser.getOSVersion() === 'string' &&
    parser.getOSVersion().startsWith('9.')
  );
});
export const isMobileBrowser: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    (userAgentString && bowser.getParser(userAgentString).getPlatformType() === 'mobile') ||
    isMobileGooglebot(userAgentString)
);
export const isTabletBrowser: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getPlatformType() === 'tablet'
);
export const isDesktopBrowser: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    (userAgentString && bowser.getParser(userAgentString).getPlatformType() === 'desktop') ||
    isDesktopGooglebot(userAgentString)
);
export const isBrowser: UtilFunction = safeWindowFunc(
  () => isMobileBrowser() || isTabletBrowser() || isDesktopBrowser()
);
export const isIE: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getBrowserName() === 'Internet Explorer'
);
export const isEdge: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getBrowserName() === 'Microsoft Edge'
);
export const isChrome: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getBrowserName() === 'Chrome'
);
export const isSafari: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    userAgentString && bowser.getParser(userAgentString).getBrowserName() === 'Safari'
);
export const isAndroidChrome: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    isAndroid(userAgentString) && isChrome(userAgentString)
);
export const isIosSafari: UtilFunction = safeWindowFunc(
  (userAgentString: string | null | undefined) =>
    isiOS(userAgentString) && isSafari(userAgentString)
);
export const isBot: UtilFunction = (userAgentString?: string | null | undefined) =>
  BOT_USER_AGENTS.includes(userAgentString || '');

export const isMobileEmbedded = (): boolean => {
  const doc = document;
  return doc && doc.body ? doc.body.classList.contains(EMBEDDED_WEB_CLASS_NAME) : false;
};

export const isHoverable = (): boolean => {
  const global = window;
  return global && global.matchMedia ? !global.matchMedia('(hover: none)').matches : false;
};

// Only use server-side
export const isExpressRequestMobileEmbedded = (req: Request | undefined): boolean =>
  !!req?.header(MOBILE_EMBEDDED_HEADER) ||
  (req?.get('cookie') || '').includes(MOBILE_EMBEDDED_COOKIE);

// https://stackoverflow.com/a/39473604
export const isReactNative = (passedDocument?: any): boolean => {
  let doc = passedDocument;

  if (!doc && typeof window === 'object') {
    doc = window.document;
  }

  if (doc) {
    return false;
  }

  return typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
};

type SourceDescription = 'android' | 'ios' | 'mobile_web' | 'web' | '';

export const getSourceDescription = (
  userAgentString?: string | null | undefined
): SourceDescription => {
  let source: SourceDescription = '';

  if (isMobileBrowser(userAgentString)) {
    if (isMobileEmbedded()) {
      if (isAndroid(userAgentString)) {
        source = 'android';
      } else if (isiOS(userAgentString)) {
        source = 'ios';
      }
    } else {
      source = 'mobile_web';
    }
  } else {
    source = 'web';
  }

  return source;
};
