/*
 * Injector
 * Sometimes a module needs to reference a global variable that's present on the page.
 * By injecting it with this function you will receive a helpful error if it is not
 * present on the page, rather than an ambiguous stack trace because of an undefined
 * variable.
 *   Useage:
 *   const addPaymentMethodUrl = inject('Rover.urls.addPaymentMethod');
 */
const store = typeof window !== 'undefined' ? window : {};

const injectionErrorOrDefault = (key, what, defaultValue) => {
  if (defaultValue !== undefined) return defaultValue;
  throw new Error(`Key: '${key}' not found while injecting dependency '${what}'`);
};

const inject = (what, defaultValue?) => {
  const keys = what.split('.');
  let where: any = store;
  let key = keys[0];

  while (keys.length > 1) {
    key = keys.shift();
    where = where[key];

    if (where === undefined) {
      return injectionErrorOrDefault(key, what, defaultValue);
    }
  }

  const value = where[keys[0]];

  if (value === undefined) {
    return injectionErrorOrDefault(keys[0], what, defaultValue);
  }

  return value;
};

export default inject;
