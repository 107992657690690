import { setSentryTag } from '../ErrorReporting/sentry';

import { ObservabilityMetadataDataType } from './types';

class ObservabilityMetadata {
  private metadata: ObservabilityMetadataDataType;

  constructor() {
    this.metadata = {};
  }

  set(key: keyof ObservabilityMetadataDataType, value?: string): void {
    this.metadata[key] = value;
    setSentryTag(key, value);
  }

  data(): ObservabilityMetadataDataType {
    return this.metadata;
  }

  reset(): void {
    this.metadata = {};
  }
}

export default ObservabilityMetadata;
