import { isDevEnv } from '@rover/rsdk/src/modules/Env/node';

import { captureException, withScope } from '../sentry';
import type { ErrorContext, ErrorType } from '../types';
import { getErrorObject } from '../utils';

// This is the common captureError function for all platforms except NextJS
// It is used as-is on RxN and Web Clients, and is wrapped in a small enhancement on the server
const captureError = (
  error: ErrorType,
  errorContext?: ErrorContext,
  tracingId = '',
  logPrefix = ''
): void => {
  const errorObject: Error = getErrorObject(error);

  // On the server side, replace newlines with '\u000A' to convert to rsyslog format
  const errorStackTrace = process.env.JS_ENV_SERVER
    ? errorObject.stack?.replace(/\n/g, '\\u000A')
    : errorObject.stack;

  const logLine = `${logPrefix}${tracingId}${errorStackTrace}`;
  console.error(logLine); // eslint-disable-line no-console

  if (isDevEnv()) throw errorObject;

  withScope((scope) => {
    scope.setExtras({
      stacktrace: errorObject.stack,
    });
    captureException(errorObject, errorContext);
  });
};

export default captureError;
