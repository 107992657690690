export enum LogSeverity {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export enum PlatformEnvironment {
  DEBUG = 'debug',
  STAGING = 'staging',
  PROD = 'production',
}

export const SPLUNK_URL = 'https://http-inputs-rover.splunkcloud.com:443/services/collector/event';
