import { ErrorType, StripeError } from './types';

export function isStripeError(obj: unknown): obj is StripeError {
  return (
    typeof obj === 'object' && obj !== null && 'type' in obj && 'message' in obj && 'code' in obj
  );
}

export function getErrorObject(error: ErrorType): Error {
  if (typeof error === 'string') {
    return new Error(error);
  }

  if (error instanceof Error) {
    return error;
  }

  // Special handling for Stripe SDK errors
  // they are thrown like errors, but are not
  // instances of the Error prototype
  if (isStripeError(error)) {
    return new Error(`${error.type || ''}: ${error.code || ''} - ${error.message || ''}`);
  }

  if (error.id) {
    return new Error(error.id);
  }

  return new Error(`Non-standard error object: ${JSON.stringify(error)}`);
}

export const isParamsEmpty = (params: { [key: string]: string | null | undefined }) => {
  const keys = Object.keys(params);
  if (!keys.length) return true;
  if (keys.length > 1) return false;
  return params['0'] === ''; // Consider params empty when it looks like this: { "0": "" }
};
