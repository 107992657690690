import type { ErrorContext } from '../types';

import {
  captureException as originalCaptureException,
  captureMessage,
  getCurrentScope,
  showReportDialog,
  withScope,
} from './lib';
import { setSentryTag } from './setSentryTag';

const captureException = (error: Error, errorContext?: ErrorContext) => {
  withScope((scope) => {
    if (error.message) {
      // Force deduplication by error message
      scope.setFingerprint([error.message]);
    }

    if (errorContext) {
      const { res, errorInfo, query, pathname } = errorContext;
      scope.setExtras({
        ...(res &&
          res.statusCode && {
            statusCode: res.statusCode,
          }),
        ...errorInfo,
        query,
        pathname,
      });
    }

    originalCaptureException(error);
  });
};

export {
  captureException,
  captureMessage,
  getCurrentScope,
  showReportDialog,
  withScope,
  setSentryTag,
};
