/* eslint-disable no-console */
import { getUserAgentMetadata } from '../Network/userAgent';

import { LOCAL_LOGS_INCLUDE_METADATA } from './config';
import { LogSeverity } from './constants';
import { LoggingMetadata } from './types';

export function getDevicePlatform(): string {
  return 'web_client';
}

export function getApiMapping(): string | null {
  return window.location.hostname;
}

export async function getMetadata(): Promise<Partial<LoggingMetadata>> {
  return {
    ...getUserAgentMetadata(),
    platform: getDevicePlatform(),
  };
}

let didWarnAboutLackOfSplunkSupport = false;
export function isLoggingToSplunkEnabled(): boolean {
  // Can't log to Splunk from local machines or from codespaces
  const { hostname } = window.location;

  let isDevEnv = ['localhost', 'rover.local'].includes(hostname); // Local development
  isDevEnv = isDevEnv || hostname.includes('githubpreview.dev'); // Deprecated (2022-10-20) Codespaces preview domain
  isDevEnv = isDevEnv || hostname.includes('app.github.dev'); // Codespaces preview domain

  if (isDevEnv) {
    if (!didWarnAboutLackOfSplunkSupport) {
      console.warn(`Your current host (${hostname}) does not support logging to Splunk`);
      didWarnAboutLackOfSplunkSupport = true;
    }
    return false;
  }

  return true;
}

export function logLocal(
  message: string,
  metadata: Record<string, string>,
  severity: LogSeverity,
  error?: Error
): void {
  const logArgs: [string, (Error | Record<string, string>)?, Record<string, string>?] = [message];
  let logMethod = 'log';

  if (severity === LogSeverity.ERROR) {
    logMethod = 'error';
    logArgs.push(error);
  } else if (severity === LogSeverity.WARN) {
    logMethod = 'warn';
  } else if (severity === LogSeverity.DEBUG) {
    logMethod = 'debug';
  }
  if (LOCAL_LOGS_INCLUDE_METADATA) {
    logArgs.push(metadata);
  }

  console[logMethod](...logArgs);
}
