import { MessageContent } from '../../utils/queue/index';

import { LogSeverity } from './constants';

export default class LoggingMessage implements MessageContent {
  message: string;

  severity: LogSeverity;

  metadata?: Record<string, string>;

  error?: Error;

  constructor({ message, severity, metadata, error }) {
    this.message = message;
    this.severity = severity;
    this.metadata = metadata;
    this.error = error;
  }
}
