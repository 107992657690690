import { Result } from 'neverthrow';
import uuid from 'uuid';

import captureError from '../../modules/ErrorReporting/captureError';

import QueueSendError, { QueueMaxRetriesError } from './QueueSendError';

export type UUID = string;
export type MessageQueueBatchResult = Promise<Result<UUID[], QueueSendError>>;

export interface MessageContent {}
export interface MessageQueueOptions {
  batchInterval?: number;
  maxBatchCount?: number;
  maxRetries?: number;
}

export interface Message<MC extends MessageContent> {
  id: UUID;
  retries: number;
  content: MC;
}

// captureError throws when NODE_ENV is 'development',
// and we don't want to throw in the queue because it breaks retrying
const captureErrorNoThrow = (error: Error): void => {
  try {
    captureError(error);
  } catch (e) {
    // do nothing
  }
};

export default class MessageQueue<MC extends MessageContent> {
  private sendBatch: (messageBatch: Array<Message<MC>>) => MessageQueueBatchResult;

  private batchInterval: number;

  private maxBatchCount: number;

  private maxRetries: number;

  private messageQueue: Array<Message<MC>> = [];

  private intervalID: number = -1;

  constructor(
    sendBatch: (messageBatch: Array<Message<MC>>) => MessageQueueBatchResult,
    options?: MessageQueueOptions
  ) {
    this.sendBatch = sendBatch;
    this.batchInterval = options?.batchInterval || 1000;
    this.maxBatchCount = options?.maxBatchCount || -1;
    this.maxRetries = options?.maxRetries || 5; // set to -1 to allow infinite retries
    this.messageQueue = [];
  }

  push(messageContent: MC): void {
    this.messageQueue.push({
      id: uuid.v4(),
      retries: 0,
      content: messageContent,
    });
    if (this.maxBatchCount !== -1 && this.messageQueue.length >= this.maxBatchCount) {
      this.flush();
    }
  }

  start(): void {
    if (this.intervalID === -1) {
      // actually have to ignore here because there are some odd differences on the type for this in react-native
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      this.intervalID = setInterval(() => this.flush(), this.batchInterval);
    }
  }

  async end(): Promise<number> {
    if (this.intervalID !== -1) {
      clearInterval(this.intervalID);
      this.intervalID = -1;
    }

    await this.flush();
    return this.messageQueue.length;
  }

  isStarted(): boolean {
    return this.intervalID !== -1;
  }

  private async flush(): Promise<void> {
    if (this.messageQueue.length > 0) {
      const messagesToSend = this.messageQueue;
      this.messageQueue = [];

      (await this.sendBatch(messagesToSend)).match(
        (uuids) => {
          // this error handling is for when the sendBatch succeeds but not all messages are sucessful
          const uuidsSent = new Set(uuids);
          const failedMessages = messagesToSend.filter((message) => {
            return !uuidsSent.has(message.id);
          });
          for (const failedMessage of failedMessages) {
            failedMessage.retries += 1;
            // if retries are not infinite and the message has reached max retries, report an error
            if (this.maxRetries !== -1 && failedMessage.retries >= this.maxRetries) {
              captureErrorNoThrow(new QueueMaxRetriesError());
            }
          }
          this.messageQueue.push(...failedMessages);
        },
        // this error handling is for when the entire sendBatch fails
        (error) => {
          if (error.retryable) {
            for (const message of messagesToSend) {
              message.retries += 1;
              // if retries are not infinite and the message has reached max retries, report an error
              if (this.maxRetries !== -1 && message.retries >= this.maxRetries) {
                captureErrorNoThrow(new QueueMaxRetriesError());
              }
            }
            this.messageQueue.push(...messagesToSend);
          } else {
            // report errors that can't be retried
            captureErrorNoThrow(error);
          }
        }
      );

      // if retries are not infinite, remove all messages that have reached max retries
      if (this.maxRetries !== -1) {
        this.messageQueue = this.messageQueue.filter((message) => {
          return message.retries < this.maxRetries;
        });
      }
    }
  }
}
