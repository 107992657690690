import { PlatformEnvironment } from './constants';
import { getApiMapping } from './internals';

export async function getApiEnvironmentName(platform: string): Promise<PlatformEnvironment | null> {
  const apiBase = await getApiMapping();

  if (!apiBase) {
    return null;
  }

  if (apiBase.endsWith('.rover.com')) {
    return PlatformEnvironment.PROD;
  }

  if (apiBase.endsWith('.roverstaging.com')) {
    /**
     * iOS and Android only have prod and debug Splunk indexes. While indexes
     * will be based on the API mapping instead of build environment, we still
     * want to use the existing native platform indexes until we have adopted
     * this module more widely in the app.
     */
    return platform === 'web_client' ? PlatformEnvironment.STAGING : PlatformEnvironment.DEBUG;
  }

  return null;
}
