/* eslint-disable max-classes-per-file */
export default abstract class QueueSendError extends Error {
  retryable?: true;

  constructor(public message: string) {
    super(message);
    this.name = 'QueueSendError';
    // Set the prototype explicitly, see https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // extending classes will also need to do this
    Object.setPrototypeOf(this, QueueSendError.prototype);
  }
}

export class QueueSendNetworkError extends QueueSendError {
  constructor(message?: string) {
    super(message || 'Generic QueueSendNetworkError');
    this.name = 'QueueSendNetworkError';
    this.retryable = true;
    Object.setPrototypeOf(this, QueueSendNetworkError.prototype);
  }
}

export class QueueSendHttpError extends QueueSendError {
  constructor(message?: string) {
    super(message || 'Generic QueueSendHttpError');
    this.name = 'QueueSendHttpError';
    this.retryable = true;
    Object.setPrototypeOf(this, QueueSendHttpError.prototype);
  }
}

export class QueueSendConfigurationError extends QueueSendError {
  constructor(message?: string) {
    super(message || 'Generic QueueSendConfigurationError');
    this.name = 'QueueSendConfigurationError';
    Object.setPrototypeOf(this, QueueSendConfigurationError.prototype);
  }
}

export class QueueSendUnknownError extends QueueSendError {
  constructor(public sourceErr: unknown) {
    super(`Unknown error: ${sourceErr}`);
    this.name = 'QueueSendUnknownError';
    this.retryable = true;
    Object.setPrototypeOf(this, QueueSendUnknownError.prototype);
  }
}

export class QueueNoMessageError extends QueueSendError {
  constructor() {
    super(`Message array is empty`);
    this.name = 'QueueNoMessageError';
    Object.setPrototypeOf(this, QueueNoMessageError.prototype);
  }
}

export class QueueMaxRetriesError extends QueueSendError {
  constructor() {
    super(`Message has reached max retries`);
    this.name = 'QueueMaxRetriesError';
    Object.setPrototypeOf(this, QueueMaxRetriesError.prototype);
  }
}
